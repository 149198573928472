import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/userContext';
import HttpsRedirect from 'react-https-redirect';
import ReactGA from 'react-ga';
const App = React.lazy(() => import('./App'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const CreateMember = React.lazy(() => import('./pages/CreateMember'));
const DeleteAccount = React.lazy(() => import('./pages/DeleteAccount'));
const IdeasAndInspiration = React.lazy(() => import('./pages/IdeasAndInspiration'));
const Login = React.lazy(() => import('./pages/Login'));
const MyProfile = React.lazy(() => import('./pages/MyProfile'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const SubmitYourStory = React.lazy(() => import('./pages/SubmitYourStory'));
const TermsOfUse = React.lazy(() => import('./pages/TermsOfUse'));
const FamilyMember = React.lazy(() => import('./pages/FamilyMember'));
const ThankYouForRegistering = React.lazy(() => import('./pages/ThankYouForRegistering'));
const LostPassword = React.lazy(() => import('./pages/LostPassword'));
const Loading = React.lazy(()=>import("./components/Loading"));
const Admin = React.lazy(() => import('./pages/admin'));
const Page404 = React.lazy(() => import('./pages/Page404'));
const Settings = React.lazy(() => import('./pages/Settings'));
const UpdateInfo = React.lazy(() => import('./pages/UpdateInfo'));
const Recover = React.lazy(() => import('./pages/Recover'));
const CreatePet = React.lazy(() => import('./pages/CreatePet'));
const ManageSuscription = React.lazy(() => import('./pages/ManageSuscription'));
const NewSuscription = React.lazy(() => import('./pages/newSuscription'));
const CanceledSuscription = React.lazy(() => import('./pages/canceledSuscription'));
const SurveyPage = React.lazy(() => import('./pages/Survey'));
const ProtectedRoute = React.lazy(() => import('./components/protectedRoute'));
const Layout = React.lazy(() => import('./components/layout'));
const ChangePassword = React.lazy(() => import('./pages/changePassword'));
ReactGA.initialize('G-1D6C8ZQX4E');
const router = createBrowserRouter([
  {
    path: "/",
    element:
    <Layout> 
      <App/>
    </Layout>,
  },
  {
     path: "/AboutUs",
    element: 
    <Layout>
      <AboutUs/>
    </Layout>,
  },
  {
     path: "/ContactUs",
     element:
            <Layout> 
              <ContactUs/>
            </Layout>,
  },
  {
     path: "/CreateMember",
    element:
     <ProtectedRoute>
        <Layout>
          <CreateMember/>
        </Layout>
     </ProtectedRoute>
  },

  {
    path: "/Settings/DeleteAccount",
    element: <ProtectedRoute>
              <Layout>
              <DeleteAccount/>
              </Layout>
             </ProtectedRoute>,
  },
  {
    path: "/Settings/UpdateInfo",
    element: <ProtectedRoute>
              <Layout>
              <UpdateInfo/>
              </Layout>
             </ProtectedRoute>,
  },
  {
    path: "/Settings",
    element: <ProtectedRoute>
              <Layout>
              <Settings/>
              </Layout>
              </ProtectedRoute>,
  },
  {
    path: "/IdeasAndInspiration",
    element:
            <Layout> 
            <IdeasAndInspiration/>
            </Layout>,
  },         
  {
    path: "/MyProfile",
    element: <ProtectedRoute>
              <Layout> 
                <MyProfile/>
              </Layout>
             </ProtectedRoute>,
  },
  {
    path: "/PrivacyPolicy",
    element:
            <Layout> 
            <PrivacyPolicy/>
            </Layout>,
  },
  {
    path: "/LostPassword",
    element:
      <Layout> 
      <LostPassword/>
      </Layout>,
  },
  {
    path: "/SignUp",
    element:
        <Layout> 
          <SignUp/>
        </Layout>,
  },
  {
    path: "/SubmitYourStory",
    element:
      <Layout> 
      <SubmitYourStory/>
      </Layout>,
  },
  {
    path: "/Login",
    element:
        <Layout> 
          <Login/>
        </Layout>,
  },
  {
    path: "/TermsOfUse",
    element:
        <Layout> 
          <TermsOfUse/>
        </Layout>,
  },
  {
    path: "/ThankYouForRegistering",
    element:
      <Layout> 
        <ThankYouForRegistering/>
      </Layout>,
  },
  
  {
    path: "/FamilyMember/:id/:preview?",
    element: 
      <Layout>
        <FamilyMember/>
      </Layout>,
  },
  {
    path: "/Admin",
    element: <Admin/>,
  },
  {
    path: '*',
    element:
          <Layout> 
            <Page404 />
          </Layout>,
  },
  {
    path: "/recover/:token/:email",
    element:
    <Layout>
      <Recover/>
    </Layout>,
  },
  {
    path: "/Settings/ManageSuscription",
    element: <ProtectedRoute>
                <Layout>
                  <ManageSuscription/>
                </Layout>   
              </ProtectedRoute>,
  },
  {
    path: "/CreatePet",
    element: <ProtectedRoute>
              <Layout>
                <CreatePet/>
              </Layout>
            </ProtectedRoute>,
  },
  {
    path: "/NewSubscription",
    element: <Layout><NewSuscription/></Layout>
  },
  {
    path: "/CanceledSubscription",
    element:
    <Layout> <CanceledSuscription/></Layout> 
     
  },
  {
    path: "/Survey",
    element: <Layout><SurveyPage/></Layout>,
  },
  {
    path: "/Settings/ChangePassword",
    element: <ProtectedRoute>
                <Layout>
                  <ChangePassword/>
                </Layout>
              </ProtectedRoute>,
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HttpsRedirect>
      <UserProvider>
        <Suspense fallback={<Loading />}>
          <RouterProvider router={router}/>
        </Suspense>
      </UserProvider>
  </HttpsRedirect>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
